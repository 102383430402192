import CopyableInput from '../components/CopyableInput';
import Tabs from '../components/Tabs';
import Form from '../components/Form';

let copyableInputs = [];
let tabs = [];

// Copyable Inputs
document.querySelectorAll('.input_copyable')
    .forEach((item) => {
        const input = item.querySelector('.input__input');
        const copy = item.querySelector('.input__copy');

        copyableInputs.push(new CopyableInput(input, copy));
    });

// Tabs
document.querySelectorAll('[data-tabs-group-id][data-radios]')
    .forEach((item) => {
        const itemGroupId = item.attributes['data-tabs-group-id'].value;

        const radios = item.querySelectorAll(`[name=${itemGroupId}]`);
        const sheets = document.querySelectorAll(`[data-tabs-group-id=${itemGroupId}][data-tab]`);

        tabs.push(new Tabs(radios, sheets));
    });

// Download buttons
document.querySelectorAll('[data-qr-url]')
    .forEach((item) => {

        item.parentNode.addEventListener('click', (e) => {
            if (e.target !== item) {
                return;
            }

            const url = item.attributes['data-qr-url'].value;

            download(url);
        });
    });

function download(url) {
    const a = document.createElement('a');
    a.href = url;
    a.download = url.split('/').pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

// Forms
document.querySelectorAll('[data-form]')
    .forEach((form) => {
        new Form(
            form,
            (response) => (handleSuccess(form, response)),
            (response) => (handleError(form, response)),
            (form) => (beforeRequest(form)),
            (form) => (endLoader(form)),
        );
    });

function handleSuccess(form, response) {
    const json = JSON.parse(response.responseText).data;

    // Getting values from response
    const link = window.location.origin + '/code/' + json.id;
    const shortLink = json.resultPayload;
    const image = json.images.png;

    const resultContainer = document.querySelector('[data-result]');

    resultContainer.removeAttribute('hidden');

    // Getting values containers
    const imageTag = resultContainer.querySelector('[data-qr-image]');
    const linkInput = resultContainer.querySelector('[data-qr-permalink]');
    const shortLinkInput = resultContainer.querySelector('[data-qr-shortened-link]');
    const shortLinkInputContainer = shortLinkInput.parentNode.parentNode;
    const shortLinkInputLabel = shortLinkInputContainer.previousElementSibling;

    // Settings values to value containers
    imageTag.setAttribute('src', image);
    linkInput.value = link;

    if (!shortLink) {
        shortLinkInputLabel.setAttribute('hidden', true);
        shortLinkInputContainer.setAttribute('hidden', true);
    } else {
        shortLinkInputLabel.removeAttribute('hidden');
        shortLinkInputContainer.removeAttribute('hidden');
    }

    shortLinkInput.value = shortLink;

    resultContainer.querySelectorAll('[data-qr-url]')
        .forEach((input) => {
            const downloadType = input.attributes.value.value;
            input.setAttribute('data-qr-url', json.images[downloadType]);
        });
}

function handleError(form, response) {
    const formError = form.querySelector('[data-form-error]');
    const json = JSON.parse(response.responseText);

    if (json.message) {
        formError.innerText = json.message;
    } else {
        formError.innerText = 'Неизвестная ошибка. Попробуйте ещё раз.';
    }

    formError.removeAttribute('hidden');
}

function beforeRequest(form) {
    const formError = form.querySelector('[data-form-error]');

    formError.setAttribute('hidden', true);

    const button = form.querySelector('[data-submit]');

    button.setAttribute('disabled', true);
    button.innerText = 'Загрузка...';
}

function endLoader(form) {
    const button = form.querySelector('[data-submit]');

    button.removeAttribute('disabled');
    button.innerText = 'Создать код';
}

// Url input formatter

document.querySelector('[name="url"]').addEventListener('input', function () {
    this.value = format(this.value);
});

function format(value) {
    if (value === 'https:/') {
        value = 'https://';
        return value;
    }

    if (value.indexOf('https://') === -1 && value.indexOf('http://') === -1) {
        value = 'https://' + value;
    }

    return value;
}
