export default class Form {
    constructor(
        formNode,
        onSuccess = () => ({}),
        onError = () => ({}),
        onBeforeSubmit = () => ({}),
        onResponsed = () => ({}),
    ) {
        this._form = formNode;
        this._beforeSubmitHandler = onBeforeSubmit;
        this._responsedHandler = onResponsed;
        this._successHandler = onSuccess;
        this._errorHandler = onError;

        this._handle();
    }

    _handle() {
        this._form.addEventListener('submit', (event) => this.submitForm(event));
    }

    submitForm(event) {
        event.preventDefault();

        this._beforeSubmitHandler(this._form);

        const data = new FormData(this._form);

        const xhr = new XMLHttpRequest();
        xhr.open(
            'POST',
            this._form.attributes['action'].value,
            true,
        );

        xhr.onreadystatechange = (readyStateEvent) => {
            if (readyStateEvent.target.readyState !== 4) {
                return;
            }

            if (readyStateEvent.target.status === 200) {
                this._successHandler(readyStateEvent.target);
                this._responsedHandler(this._form);
                return;
            }

            this._errorHandler(readyStateEvent.target);
            this._responsedHandler(this._form);
        };

        xhr.send(data);
    }
}
