export default class Input {
  constructor(inputNode) {
    this._input = inputNode;
  }

  get name() {
    return this._input.name;
  }

  get value() {
    return this._input.value;
  }

  set value(value) {
    this._input.value = value;
  }
}
