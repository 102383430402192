export default class Tabs {
  constructor(tabsRadios, tabsSheets, idToSelectOnStart = null) {
    this._tabsRadios = tabsRadios;
    this._tabsSheets = tabsSheets;

    this._handle();
    this._init(idToSelectOnStart);
  }

  _handle() {
    this._tabsRadios.forEach((item) => {
      item.addEventListener('change', () => this.selectTabById(item.value));
    });
  }

  _init(idToSelectOnStart = null) {
    if (idToSelectOnStart) {
      this.selectTabById();
      return;
    }

    const firstIdToSelect = this._tabsSheets[0].attributes['data-tab'].value;
    this.selectTabById(firstIdToSelect);
  }

  get name() {
    return this._tabsSheets[0].attributes['data-tabs-group-id'].value
  }

  get selectedTabId() {
    return Array.from(this._tabsRadios).find((tab) => (tab.attributes.checked)).value;
  }

  selectTabById(id) {
    this._tabsSheets.forEach((sheet) => {
      const sheetId = sheet.attributes['data-tab'].value;

      if (sheetId !== id) {
        sheet.setAttribute('hidden', true);
        return;
      }
      sheet.removeAttribute('hidden');
    })
  }
}
