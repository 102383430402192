import copy from 'copy-to-clipboard';

import Input from './Input';

export default class CopyableInput extends Input {
  constructor(inputNode, copyButton) {
    super(inputNode);

    this._copyButton = copyButton;
    this._handle();
  }

  _handle() {
    this._copyButton.addEventListener('click', () => this.copyInputValue());
  }

  copyInputValue() {
    copy(this._input.value);
  }
}
